import React from 'react'
import './services.css'
import { BsCheckLg } from 'react-icons/bs'

const Services = () => {
    return (
        <section id='services'>
            <h5>My History</h5>
            <h2>Working -- Certificate -- Project</h2>

            <div className="container services__containter">
                {/* start Mô tả chi tiết */}
                <article className="service">
                    <div className="service__head">
                        <h3>Join with Company</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>1 Cloud Star - From Dec2021 To Current</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>TeamWork VietNam - From May2020 To Dec2021</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>YKK VietNam - From May2014 To Apr2020</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Duc Nhan Co Ltd., - From May2012 To May2014</p>
                        </li>
                    </ul>
                </article>
                {/* End of ui/ux */}

                {/* Mô tả chi tiết */}
                <article className="service">
                    <div className="service__head">
                        <h3>Certification</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>SOA-C02 - AWS SysOps Administrator Associate </p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>SAA-C02 - AWS Solutions Architect Associate</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AZ-104 - Microsfoft Azure Administrator </p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Microsoft Azure Solution Architect Expert</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>LPIC-1 - Linux System Administrator</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>VMWare Data Center Virtualization 2020</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>ISO/IEC 27001 — Information security management</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>IT Certificate from LacHong University</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Bussiness Administrator Certificate from LacHong University</p>
                        </li>
                    </ul>
                </article>
                {/* End of ui/ux */}

                {/* start Mô tả chi tiết */}
                <article className="service">
                    <div className="service__head">
                        <h3>1CS Project</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Linux – Rocky 9 CIS Benmark</p>
                        </li>
                    </ul>
                </article>
                <article className="service">
                    <div className="service__head">
                        <h3>1CS Project</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Checkpoint – S2S VPN</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Azure Project 3Apr23</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Project for LQP</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Fortigate POC</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS - Static Analysis with TFLint/Terrascan, Alerting for IaC with Jenkins/SNS</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS - Build CI/CD with AWS Code Service, ELB, B/G Env</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS - Deploying a PHP Web Application Using AWS OpsWorks</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Create a Python Flask web app - Launch a dual container (Nginx -> Flask)</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS - Controlled Version Deploy with Beanstalk</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Terraform - Battelab Project</p>
                        </li> 
                    </ul>
                </article>
                <article className="service">
                    <div className="service__head">
                        <h3>1CS Project</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Linux - CIS Benmark</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/FW - Checkpoint fw one way to internet</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Audit - RDP Project</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/FW - Configurate s2s VPN - PFSense</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/FW - Upgrade Checkpoint FW</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS - Migrate all infra to new VPC</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Cloudformation - Provision new Sophos FW and config</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Docker - Update new image - Deploy to new container</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>Azure - Design new System for new Customer</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>On - Replication Veeam Backup between 2 site</p>
                        </li>
                    </ul>
                </article>
                {/* End of ui/ux */}
                <article className="service">
                    <div className="service__head">
                        <h3>TeamWork Project</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>On/MSSQL - Antivirus Report (Win/Linux)</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>On/MSSQL - SCCM Report</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Lambda - Get Price for all instance</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>On/Powershell - Auto get Daily Report</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS/Cloudformation - Sent email/sms with SNS</p>
                        </li>
                        <li>
                            <BsCheckLg className='service__list-icon' />
                            <p>AWS - Create Alarm with Cloudwatch</p>
                        </li>
                    </ul>
                </article>

            </div>
        </section>
    )
}

export default Services