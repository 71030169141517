import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { HiOutlineMail } from 'react-icons/hi'
import { HiPhone } from 'react-icons/hi'


const HeaderSocials = () => {
    return (
        <div className='header__socials'>
            <a href="https://www.linkedin.com/in/ctd983/" target="_blank"><BsLinkedin /></a>
            <a href="mailto:ctd983@gmail.com" target="_blank"><HiOutlineMail /></a>
            <a href="tel:+84933243889"><HiPhone /></a>
        </div>
    )
}

export default HeaderSocials