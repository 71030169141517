import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'
import CV from '../../assets/Resume_TranDoanCuong.pdf'

const Experience = () => {
    return (
        <section id='experience'>
            <h5>What Skill I Have</h5>
            <h2>My Experience</h2>

            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Public Cloud</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>AWS - Amanzon Web Serices</h4>
                                <small className='text-light'>★★★★☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Microsoft Azure</h4>
                                <small className='text-light'>★★☆☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>
                {/* End of front end */}

                <div className="experience__backend">
                    <h3>Virtual System</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>VMWare Technology</h4>
                                <small className='text-light'>★★★★☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Microsoft Hyper-V</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <h2></h2>
            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>DataBase</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Microsoft SQL</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>MySQL</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Redis</h4>
                                <small className='text-light'>★★☆☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Postgres</h4>
                                <small className='text-light'>★★☆☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>
                {/* End of front end */}

                <div className="experience__backend">
                    <h3>Coding</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Python</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Powershell</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Linux Bash Shell</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Yaml</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Json</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>CloudFormation</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Terraform</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>

            </div>
            <h2></h2>
            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Backup</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Veeam Backup</h4>
                                <small className='text-light'>★★★★☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Hyper-V CheckPoint</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>AWS Snapshot</h4>
                                <small className='text-light'>★★★★☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Simpana</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>
                {/* End of front end */}

                <div className="experience__backend">
                    <h3>Firewall</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Checkpoint</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>FortiGate</h4>
                                <small className='text-light'>★★★★☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Pfsense</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Sophos</h4>
                                <small className='text-light'>★★☆☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>
                
                <div className="experience__backend">
                    <h3>CI/CD Tools</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>GitLabCI</h4>
                                <small className='text-light'>★★☆☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>GitHub Action</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Bitbucket</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>AWS Code Service</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Jenkins</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="experience__backend">
                    <h3>Containers</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Docker</h4>
                                <small className='text-light'>★★★☆☆</small>
                            </div>
                        </article>

                        <article className='experience__details'>
                            <BsPatchCheckFill className='experience__details-icon' />
                            <div>
                                <h4>Kubernetes</h4>
                                <small className='text-light'>★★☆☆☆</small>
                            </div>
                        </article>
                    </div>
                </div>


            </div>
            <h2></h2>
            <h5>Plz <a href={CV}>Download my CV</a> for READ MORE</h5>
            
        </section>
    )
}

export default Experience