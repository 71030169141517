import React from 'react'
import './footer.css'
import { FaLinkedinIn } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { HiPhone } from 'react-icons/hi'

/**
 * Thêm link tại footer dòng 26
 * <li><a href="#testimonials">Testimonials</a></li>
 */

const Footer = () => {
    return (
        <footer>
            <a href="#" className='footer__logo'>AndrewTran</a>

            <ul className='permalinks'>
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href="https://www.linkedin.com/in/ctd983/"><FaLinkedinIn /></a>
                <a href="mailto:ctd983@gmail.com"><HiOutlineMail /></a>
                <a href="tel:+84933243889"><HiPhone /></a>
            </div >

            <div className="footer__copyright">
                <small>&copy; <b><a href="https://www.linkedin.com/in/ctd983/">AndrewTran</a></b>. All rights reserved.</small>
            </div>
        </footer >
    )
}

export default Footer