import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocials from './HeaderSocials'
import { HiOutlineChevronDoubleRight } from 'react-icons/hi'

const header = () => {
    return (
        <header>
            <div className="container header__container">
                <h5> Welcome to my profile ~.~ - My Name is</h5>
                <h1>Tran Doan Cuong - Andrew Tran</h1>
                <h5 className="text-light">Senior Cloud Engineer - System Admin</h5>
                <CTA />
                <HeaderSocials />

                <div className="me">
                    <img src={ME} alt="me" />
                </div>

                <a href="#contact" className='scroll__down'><HiOutlineChevronDoubleRight /></a>
            </div>
        </header>
    )
}

export default header