import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import { FaAward } from 'react-icons/fa'
import { TbCertificate } from 'react-icons/tb'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt="About Me Image" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__cards">
                        <article className='about__card'>
                            <FaAward className='about__icon' />
                            <h5>Level</h5>
                            <small>Senior</small>
                        </article>

                        <article className='about__card'>
                            <TbCertificate className='about__icon' />
                            <h5>Certificate</h5>
                            <small>9 Certificate</small>
                        </article>

                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon' />
                            <h5>Projects</h5>
                            <small>+10 Completed</small>
                        </article>
                    </div>

                    <p>Difficult task or easy task, the important is we try to do anything we can do. Only you want or not. And with over +10 years of working with the system. I got more skills in Security, networking, Virtual system, Public Cloud, Database, OS system, Software, Dev. I also learn to improve my skill. I hope I can help your company take care of your IT system. In the past, my colleague – also my customer from Germany, France, Japan, Vietnam, Singapore, China, Korea, US, Switzerland…So I confidently integrate with the working environment and working style of European and Asian Countries.</p>

                    <a href="#contact" className='btn btn-primary'>Share with Me</a>
                </div>
            </div>
        </section>
    )
}

export default About